import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { Grid, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ExistingMerchant() {
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	
	const [ merchantId, setMerchantId ] = useState( '' );
	
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography gutterBottom variant='h4' fontWeight='500'>
					Existing Merchant
				</Typography>
				<Typography color='text.secondary' mb={2}>
					Please contact CardConnect support to create authentication credentials for your account, essential for
					integration with Invoiss. CardConnect Merchant Support contact: 877.828.0720.
				</Typography>
				<FormattedTextField
					fullWidth
					value={merchantId}
					label={t( 'common:merchant-id' )}
					onChange={( e ) => setMerchantId( e.target.value )}
				/>
				<AsyncLoadingButton
					variant='contained'
					size='large'
					color='primary'
					disabled={!merchantId}
					sx={{ my: 2, width: '100%' }}
					onClick={async () => {
						const { data } = await axios.post( `/api/processor/cardConnect/merchant/createFromId`, {
							companyId: staff?.company?.id,
							merchantId,
						} );
						if ( data?.gateway ) {
							enqueueSnackbar( 'Gateway created successfully.', { variant: 'success' } );
							closeModal();
						} else {
							enqueueSnackbar( 'There was an error creating the gateway.', { variant: 'error' } );
						}
					}}>
					{t( 'common:submit' )}
				</AsyncLoadingButton>
			</Grid>
		</Grid>
	);
}
